<template>
  <div>
    <div class="form-group">
      <label class="font-medium text-uppercase">Cihaz Türü</label>
      <div class="demo-inline-spacing no-margin-top">
        <div class="row">
          <div
            class="col-sm-4"
            v-for="(item, index) in global.device_types"
            :key="index"
          >
            <b-form-radio
              v-model="sf.device.device_type_id"
              name="sf.device.device_type_id"
              :value="item.definition_id"
              >{{ item.name }}</b-form-radio
            >
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col sm="12" lg="6">
        <div class="form-group">
          <label class="font-medium text-uppercase">Marka</label>
          <v-select
            v-model="sf.device.brand_id"
            :options="global.device_brands"
            :reduce="(option) => option.definition_id"
            :searchable="true"
            :clearable="false"
          />
        </div>
      </b-col>
      <b-col sm="12" lg="6">
        <div class="form-group">
          <label class="font-medium text-uppercase">Model</label>
          <input
            type="text"
            class="form-control"
            v-model="sf.device.model_name"
            v-uppercase
          />
        </div>
      </b-col>
      <b-col sm="12" lg="6">
        <div class="form-group">
          <label class="font-medium text-uppercase">Barkod</label>
          <input
            type="text"
            class="form-control"
            v-model="sf.device.device_barcode"
            v-uppercase
            placeholder="Sadece şirket içi barkod yazın"
          />
        </div>
      </b-col>
      <b-col sm="12" lg="6">
        <div class="form-group">
          <label class="font-medium text-uppercase">* Cihaz Seri No</label>
          <input
            type="text"
            class="form-control"
            v-model="sf.device.device_serial_number"
            v-uppercase
            placeholder="Geçmişe yönelik sorgulamalar için lütfen doldurun"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="6">
        <b-row>
          <b-col sm="12" lg="6">
            <div class="form-group">
              <label class="text-uppercase font-medium text-danger"
                >Çıkış Tarihi</label
              >
              <flat-pickr
                v-model="sf.device.due_date"
                class="form-control"
                :config="{
                  disabled: true,
                  enableTime: false,
                  altFormat: 'd.m.Y',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  locale: 'tr',
                }"
              />
            </div>
            <div class="form-group mt-3">
              <label class="font-medium text-uppercase">Sorumlu Personel</label>
              <v-select
                :searchable="true"
                :clearable="false"
                v-model="sf.device.staff_id"
                :reduce="(curr) => curr.value"
                :options="global.users"
              />
            </div>
          </b-col>
          <b-col sm="6" lg="6">
            <div class="form-group">
              <label for=""></label>
              <b-form-checkbox
                v-model="sf.device.is_immediate"
                class="mt-1 custom-control-danger"
              >
                <span class="font-medium text-danger text-uppercase">
                  Acil Çıkışı Gerekiyor</span
                >
              </b-form-checkbox>
            </div>
            <div class="form-group">
              <b-form-checkbox
                v-model="sf.device.is_hasproblem"
                class="custom-control"
              >
                <span class="font-medium text-uppercase"> Problemli Cihaz</span>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="6" lg="6">
        <div class="form-group">
          <label class="text-uppercase font-medium">Yedek Durumu</label>
          <v-select
            v-model="sf.device.backup_id"
            :options="global.device_backup_status"
            :reduce="(option) => option.definition_id"
            :searchable="false"
            :clearable="false"
          />
        </div>
        <div class="form-group">
          <label class="text-uppercase font-medium">Sistem Parolası</label>
          <input
            v-model="sf.device.password_system"
            type="text"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label class="text-uppercase font-medium">Bios Parolası</label>
          <input
            v-model="sf.device.password_bios"
            type="text"
            class="form-control"
          />
        </div>
      </b-col>
    </b-row>
    <div class="form-group mt-4 d-flex justify-content-between">
      <button v-on:click="saveDeviceItem()" class="btn btn-primary">
        Kaydet
      </button>
      <div v-if="sf.device.device_id > 0">

        <a :href="fischeLink" target="_blank" class="btn btn-secondary ml-1">
        Etiket Fişi Yazdır
        </a>
        <router-link
        v-if="false"
          class="btn btn-secondary ml-1"
          :to="{
            name: 'printfiletag',
            query: { uniq: sf.sform.sf_uniq, device_id: sf.device.device_id },
          }"
        >
          Etiket Fişi Yazdır
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import ServiceDevice from "./ServiceDevice.vue";
import utils from "@/service/utils";
export default {
  components: {
    vSelect,
    flatPickr,
    ServiceDevice,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["saveDevice", "saveDeviceStatus"]),
    saveDeviceItem() {
      this.saveDevice().then((q) => {
        if (q == null) return;
      });
    },
    setDeviceStatusEntity(item, target, val) {
      let d = Object.assign({}, item);
      d.is_current = "1";
      if (target == "is_processing") {
        d.is_processing = val;
      }
      if (target == "is_complated") {
        d.is_complated = val;
      }
      this.saveDeviceStatus(d);
    },
    moment(v) {
      return utils.moment(v);
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      customer_device_request() {
        let result = [];
        this.global.device_customer_request.forEach((q) => {
          let req = this.sf.device.customer_requests;
          let findItem = req.find((qq) => qq.definition_id == q.definition_id);

          result.push({
            definition_name: q.name,
            definition_id: q.definition_id,
            notes: findItem && findItem.notes ? findItem.notes : "",
            selected: findItem ? true : false,
          });
        });

        return result;
      },
      fischeLink()
      {
        console.log('dsd',this.sf);

        return 'https://api.tkabilisim.com.tr/api/print?key=print-label&device='+this.sf.device.device_uniq
      }
    }),
  },
};
</script>
 
 <style scoped>
</style>